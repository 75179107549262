<template>
  <v-main>
    <v-container fluid>
      <v-layout>
        <v-flex xs12 sm8 md6 lg4>
          <h2 class="display-1">Comentarios y sugerencias</h2>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  components: {},

  data: () => ({}),
};
</script>
